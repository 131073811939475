<template>
  <section class="memberinfo-wrapper">
    <card title="会员信息管理">
      <el-button
        class="card-btn"
        size="small"
        type="primary"
        @click="editTag"
        >{{ closeFlag ? "完成" : "编辑" }}</el-button
      >
      <div class="tag-wrapper">
        <span class="tag-label">必填项:</span>
        <el-tag
          :key="tag.aid"
          v-for="tag in requiredTags"
          :closable="closeFlag"
          :disable-transitions="false"
          @close="handleClose(tag.aid)"
        >
          {{ tag.aname }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="requiredValue"
          ref="saveTagInput1"
          size="small"
          @keyup.enter.native="handleInputConfirm(1)"
          @blur="handleInputConfirm(1)"
        >
        </el-input>
        <el-button
          v-else-if="closeFlag"
          class="button-new-tag"
          size="small"
          @click="showInput(1)"
          >+ New Tag</el-button
        >
      </div>
      <div class="tag-wrapper">
        <span class="tag-label">选填项:</span>
        <el-tag
          :key="tag.aid"
          v-for="tag in optionalTags"
          :closable="closeFlag"
          :disable-transitions="false"
          @close="handleClose(tag.aid)"
        >
          {{ tag.aname }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="optionalValue"
          ref="saveTagInput0"
          size="small"
          @keyup.enter.native="handleInputConfirm(0)"
          @blur="handleInputConfirm(0)"
        >
        </el-input>
        <el-button
          v-else-if="closeFlag"
          class="button-new-tag"
          size="small"
          @click="showInput(0)"
          >+ New Tag</el-button
        >
      </div>
      <!-- <div class="tag-wrapper">
        <span class="tag-label">收货信息:</span>
        <el-tag
          :key="tag"
          v-for="tag in infoTags"
          :closable="closeFlag"
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="infoValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button
          v-else-if="closeFlag"
          class="button-new-tag"
          size="small"
          @click="showInput"
          >+ New Tag</el-button
        >
      </div> -->
    </card>
  </section>
</template>

<script>
import card from "../../components/card";
export default {
  components: {
    card
  },
  data() {
    return {
      requiredTags: [],
      optionalTags: [],
      infoTags: [],
      inputVisible: false,
      requiredValue: "",
      optionalValue: "",
      tempValue: "",
      infoValue: "",
      closeFlag: false
    };
  },
  methods: {
    handleClose(id) {
      this.$axios({
        url: "/back/user/deleteAttribute",
        params: {
          id: id
        }
      }).then(res => {
        this.getTags();
      });
    },

    showInput(type) {
      this.inputVisible = true;
      this.$nextTick(_ => {
        if (type == 0) this.$refs.saveTagInput0.$refs.input.focus();
        else this.$refs.saveTagInput1.$refs.input.focus();
      });
    },
    getTags() {
      this.$axios({
        url: "/back/user/getAllAttribute"
      })
        .then(res => {
          if (res.data.code == 200) {
            this.requiredTags = [];
            this.optionalTags = [];
            res.data.data.forEach(item => {
              if (item.required == 1) {
                this.requiredTags.push(item);
              } else {
                this.optionalTags.push(item);
              }
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            });
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: "获取信息失败"
          });
        });
    },
    handleInputConfirm(type) {
      if (this.requiredValue || this.optionalValue) {
        let tempValue = type == 0 ? this.optionalValue : this.requiredValue;
        this.$axios({
          url: "/back/user/addAttribute",
          params: {
            attribute: tempValue,
            required: type
          }
        }).then(res => {
          this.getTags();
        });
      }
      this.inputVisible = false;
      this.optionalValue = "";
      this.requiredValue = "";
    },
    editTag() {
      this.closeFlag = !this.closeFlag;
    }
  },
  created() {
    this.$openLoading();
    Promise.all([this.getTags()]).then(res => {
      setTimeout(() => {
        this.$openLoading().close();
      }, 200);
    });
  }
};
</script>

<style lang="less">
.memberinfo-wrapper {
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin: 0 auto;
    margin-left: 10px;
  }

  .tag-wrapper {
    height: 32px;
    margin: 15px auto;
    span {
      padding: 0 20px;
    }
    .tag-label {
      margin-right: 10px;
      font-weight: 700;
      line-height: 32px;
      padding: 0;
    }
  }
}
</style>
